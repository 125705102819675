import React from 'react'

function ProductRules(props) {
    const products = props.products && props.products.filter(product => product.type === 1)

    return (
        <div>
            <span style={{fontWeight: "bold"}}>환급 정책</span><br/>
            - 집중관리 환급은 여러 대학에 합격하였더라도 최종 입학하는 대학 기준으로 환급됩니다.<br/>
            - 최종 입학하는 대학의 합격증, 등록금 납부 고지서 등의 서류를 통해 최종 등록이 확인이 되어야 장학금이 지급됩니다.<br/>
            - 환급 장학금은 국가 장학금과는 별개로 지급받으실 수 있습니다.<br/>
            - 환급 시 실결제금액에서 제세공과금 22% 공제 후 환급받으실 수 있습니다<br/>
            - 환급 시 합격 인증 자료 및 합격 후기(셀프 영상 후기 또는 인터뷰 등)를 제출해주셔야 합니다.<br/>
            - 환급 액수는 진행한 최대 수업비용을 초과하지 않는 선에서 지급됩니다<br/>
            * <a href="http://mildang.info/refund.html">환급 대상 금액 및 대학 확인하기</a><br/>
            <br/>
            <span style={{fontWeight: "bold"}}>환불 정책</span><br/>
            (「학원의 설립·운영 및 과외교습에 관한 법률 시행령」에 근거한 환불조항)<br/>
            1. 강좌 폐쇄, 또는 운영정지로 인한 반환사유에 해당하는 경우(제18조제2항제1호 및 제2호)<br/>
            - 강좌 제공 불가일 기준으로, 이미 납부한 교습비(수강료)등을 일할 계산한 금액 반환<br/>
            2. 회원 본인의사로 학습 포기에 해당하는 경우(제18조제2항제3호)<br/>
            - 수강기간(교습기간) 1개월 이내<br/>
            ☞ 교습시작(수강시작) 이전 : 이미 납부한 교습비(수강료) 등의 전액 반환<br/>
            ☞ 총 교습시작(수강기간)의 3일 경과 전 : 이미 납부한 교습비(수강료) 에서 교재비용을 차감한 금액 반환
            ☞ 총 교습시작(수강기간)의 3일 초과 시 : 반환하지 않음<br/>
            - 수강기간(교습기간) 1개월 초과<br/>
            ☞ 교습시작(수강시작) 이전 : 이미 납부한 교습비(수강료) 등의 전액 반환<br/>
            ☞ 교습시작(수강시작) 7일 경과 전 : 이미 납부한 교습비(수강료) 에서 교재비용을 차감한 금액 반환<br/>
            ☞ 교습시작(수강시작) 7일 경과 후 : 환불 접수 발생 시점기준으로 아래의 유의사항에 따라 반환금액 책정
            ☞ 총 교습시간의 1/2 초과 시 : 반환하지 않음<br/>
            - 환불 접수 발생월(신청 후 익월부터)에 대한 차감 금액은 다음 규정에 따름<br/>
            ☞ 환불접수 발생월의 수강기간 10일 이전 : 월 비용의 2/3 금액 반환<br/>
            ☞ 환불접수 발생월의 수강기간 10일 이후 : 반환하지 않음<br/>
            <br/>
            ※ 환불 유의사항<br/>
            - 교습기간(수강기간)이 종료된 경우는 반환하지 않습니다.<br/>
            - 총 교습시간(수강기간)은 총 교습기간(등록 당시 프로그램 별로 고지된 수강기간)을 말하며, 반환금액의 산정은 개강일로부터 반환사유가 발생한 날까지 경과된 교습시간(수강기간)을 기준으로
            합니다.<br/>
            - 추가적인 혜택이 제공된 특별기획상품의 경우, 부분환불 시 제공한 혜택(할인금액, 수강기간 연장 등)을 철회하고 각 강의가 속한 혜택적용 이전 시점(수강료, 수강기간)으로 환불금액을 산정할
            수 있습니다.<br/>
            - 환불 시 수강기간 중 이용한 커리큘럼의 종류와 제공받은 학습자료에 따라 수강기간에 따른 환불액이 다르게 책정될 수 있습니다.<br/>
            - 프로그램 구매 시 지급된 이벤트 상품/교재가 있을 경우, 해당 상품/교재 회수 후 환불 가능합니다.(제공된 상품/교재의 사용 혹은 반송 불가시, 상품/교재 정가금액 차감)<br/>
            - 사용 시 해당 교재 가격은 공제되며 배송비 환불은 불가. 단, 교재 반송 시 내/외부 사용 및 훼손이 없는 미사용 교재임이 확인된 경우 전액환불 가능합니다.(반송비 본인 부담)<br/>
            <br/>
            <span style={{fontWeight: "bold"}}>유의사항</span><br/>
            1. 아이디 공유 적발 시 회원 자격이 박탈되며 환불 불가함을 원칙으로 합니다.<br/>
            2. 추가적인 불법 공유 행위 적발 시 형사 고발 조치가 진행될 수 있는 점, 양지해주시기 바랍니다.<br/>
            3. 특정 이벤트 상품, 연장 결제 상품을 제외한 모든 상품은 정기과금 방식으로 결제됩니다.<br/>
            {
                Array.isArray(products) &&
                <div>
                    4. {products.map((product, key, array) => <span
                    key={key}>{product.name}은 {product.duration}일{key === array.length - 1 ? "" : ", "}</span>)}
                    의 수강기간이 제공됩니다.
                </div>
            }
        </div>
    )
}

export default ProductRules