export const studyVideos = [
    {
        id: 1,
        title: "밀당영어 69기 황가희 학습과정&후기",
        subtitle: "황가희 학생",
        url: "https://www.youtube.com/embed/IiaYdjTRmf0",
        thumbnail: "./images/introduction/studythumbnail_1.jpg"
    },
    {
        id: 2,
        title: "유튜버1타 미미미누 밀당영어 선생님 체험",
        subtitle: "미미미누",
        url: "https://www.youtube.com/embed/sjLQypk9nT0",
        thumbnail: "./images/introduction/studythumbnail_2.jpg"
    },
    {
        id: 3,
        title: "구슬쥬가 추천하는 온라인 내신대비 방법",
        subtitle: "구슬쥬",
        url: "https://www.youtube.com/embed/KOQd1C0SDwI",
        thumbnail: "./images/introduction/studythumbnail_3.jpg"
    },
    {
        id: 4,
        title: "서울대 선배가 알려주는 여름방학 영어 점수 상승 비법",
        subtitle: "스튜디오 샤",
        url: "https://www.youtube.com/embed/BjPVKUIZ0Dk",
        thumbnail: "./images/introduction/studythumbnail_4.jpg"
    }
]

export const reviewVideos = [
    {
        id: 1,
        title: "뭐혜연이 알려주는 밀당영어",
        subtitle: "뭐혜연",
        url: "https://www.youtube.com/embed/urMVaT7iBCc",
        thumbnail: "./images/introduction/reviewthumbnail_1.jpg"
    },
    {
        id: 2,
        title: "밀당영어로 쉽게 수능특강 학습하기",
        subtitle: "헨니",
        url: "https://www.youtube.com/embed/-7WAgEgNXJI",
        thumbnail: "./images/introduction/reviewthumbnail_2.jpg"
    },
    {
        id: 3,
        title: "밀당영어 공부 타임랩스",
        subtitle: "땅땅",
        url: "https://www.youtube.com/embed/627FHcAL2zg",
        thumbnail: "./images/introduction/reviewthumbnail_3.jpg"
    },
    {
        id: 4,
        title: "혜향의 브이로그",
        subtitle: "혜향",
        url: "https://www.youtube.com/embed/scAp-wqW-iQ",
        thumbnail: "./images/introduction/reviewthumbnail_4.jpg"
    },
    {
        id: 5,
        title: "지안의 브이로그",
        subtitle: "지안",
        url: "https://www.youtube.com/embed/12tjzQpnino",
        thumbnail: "./images/introduction/reviewthumbnail_5.jpg"
    },
    {
        id: 6,
        title: "다혜가다해의 브이로그",
        subtitle: "다혜가다해",
        url: "https://www.youtube.com/embed/_16UJQK3Vpg",
        thumbnail: "./images/introduction/reviewthumbnail_6.jpg"
    }
]