import React, { useState, useEffect } from 'react';
import { Dialog, TextField, Button, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import ProductRules from '../config/ProductRules';
import Footer from '../components/Footer';
import dateformat from 'dateformat';
import { pipe, map, filter } from 'remeda';

function Payment(props) {
    const { isMobile, paymentType, token, direct, extension, recurring } = props;
    const contentMaxWidth = 1920;

    const styles = {
        button: {
            display: 'inline-block',
            position: 'relative',
            width: '48%',
            margin: '1%',
            borderRadius: 10,
            border: '4px solid #FFFFFF',
            boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 8px, rgba(0, 0, 0, 0.12) 0px 1px 6px',
            cursor: 'pointer',
        },
        mobileButton: {
            display: 'inline-block',
            position: 'relative',
            width: '90%',
            margin: '0% 5% 5% 5%',
            border: '4px solid #FFFFFF',
            borderRadius: 5,
            boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 8px, rgba(0, 0, 0, 0.12) 0px 1px 6px',
            cursor: 'pointer',
        },
        productCard: {
            display: 'inline-block',
            width: 410,
            height: 110,
            margin: 12,
            padding: 50,
            borderRadius: 35,
        },
    };

    const [products, setProducts] = useState([]);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [payMethod, setPayMethod] = useState('card');
    const [product, setProduct] = useState(undefined);
    const [contentWidth, setContentWidth] = useState((window.innerWidth < contentMaxWidth ? window.innerWidth : contentMaxWidth) / 100);
    const productsType1 = products.filter((product) => product.curriculumType === 1);
    const productsType2 = products.filter((product) => product.curriculumType === 2);

    const onKeyDown = (event) => {
        if (event.key === 'Escape') {
            closeDialog();
        }
    };

    const openDialog = (product) => {
        typeof window.gtag === 'function' &&
            window.gtag('event', 'click', {
                event_category: 'button',
                event_label: `product_card`,
            });
        typeof window.gtag === 'function' &&
            window.gtag('event', 'click', {
                event_category: 'button',
                event_label: `product_card_${product.price}`,
            });
        setProduct(product);
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setProduct(undefined);
        setName('');
        setPhoneNumber('');
        setPayMethod('card');
        setDialogOpen(false);
    };

    const onChange = (stateKey, event) => {
        if (stateKey === 'name') {
            setName(event.target.value);
        } else if (stateKey === 'phoneNumber') {
            setPhoneNumber(event.target.value);
        }
    };

    const onChangePayMethod = (event, value) => {
        setPayMethod(value);
    };

    const changeCurrencyUnit = (amount) => {
        if (amount < 100000000) {
            let result = parseInt(amount / 10000);
            let remainder = amount % 10000;
            if (remainder === 0) {
                remainder = '';
            }
            if (result === 0) {
                amount = remainder;
            } else {
                amount = `${result}만${remainder}`;
            }
        }
        return amount;
    };

    const purchase = () => {
        if (!(/^[ㄱ-ㅎㅏ-ㅣ가-힣a-z0-9_-].{1,10}$/.test(name) && /^(?=.*[0-9]).{10,11}$/.test(phoneNumber))) {
            alert('이름과 연락처를 다시 확인해주세요');
        }
        closeDialog();

        const merchantUid = 'merchant_' + new Date().getTime();
        const body = {
            productId: product.id,
            pgProvider: 'uplus',
            payMethod: payMethod,
            merchantUid: merchantUid,
            name: product.group + ' ' + product.name,
            amount: product.price,
            buyerEmail: '',
            buyerName: name,
            buyerTel: phoneNumber,
            isDirect: Number(direct) === 1 ? 1 : 0,
            isExtended: Number(extension) === 1 ? 1 : 0,
            token: token,
        };

        window.$.ajax({
            type: 'POST',
            url: `https://english.management/dev/payments`,
            data: JSON.stringify(body),
            success: function (result) {
                if (payMethod === 'trans') {
                    body.insertId = result.data.insertId;
                    window.location.href = window.origin + `/?result=1&trans=1&body=${JSON.stringify(body)}`;
                } else {
                    window.IMP.init('imp02579783');
                    window.IMP.request_pay(
                        {
                            pg: body.pgProvider,
                            pay_method: body.payMethod,
                            merchant_uid: body.merchantUid,
                            name: body.name,
                            amount: body.amount,
                            tax_free: body.amount,
                            buyer_email: body.buyerEmail,
                            buyer_name: body.buyerName,
                            buyer_tel: body.buyerTel,
                            m_redirect_url: window.origin + `/?result=1`,
                            vbank_due: dateformat(Date.now() + 1000 * 60 * 60 * 2, 'yyyymmddHHMM'),
                            display: { card_quota: [2, 3] },
                        },
                        function (rsp) {
                            let msg;
                            if (rsp.success) {
                                if (rsp.pay_method === 'vbank') {
                                    msg = `가상계좌 입금 계좌번호: ${rsp.vbank_num}\n가상계좌 은행명: ${rsp.vbank_name}\n가상계좌 예금주: ${rsp.vbank_holder}\n가상계좌 입금기한: ${rsp.vbank_date}\n입금이 완료되는 대로 신청이 완료됩니다.`;
                                } else {
                                    msg = '신청이 완료되었습니다.';
                                }
                                window.location.href =
                                    window.origin + `/?result=1&imp_uid=${rsp.imp_uid}&merchant_uid=${rsp.merchant_uid}&imp_success=${rsp.success}`;
                            } else {
                                msg = `결제에 실패하였습니다.\n에러내용: ${rsp.error_msg}`;
                            }
                            alert(msg);
                        },
                    );
                }
            },
            contentType: 'application/json',
            dataType: 'json',
        });
    };

    useEffect(() => {
        const handleContentWidth = () => setContentWidth((window.innerWidth < contentMaxWidth ? window.innerWidth : contentMaxWidth) / 100);
        window.addEventListener('resize', handleContentWidth);
        return () => window.removeEventListener('resize', handleContentWidth);
    }, []);

    useEffect(() => {
        const getProducts = () => {
            let url = `https://english.management/dev/products?productType=1&paymentType=${paymentType}&recurringType=${recurring}`;

            window.$.ajax({
                type: 'GET',
                url: url,
                success: function (result) {
                    const products = pipe(
                        result.data.products,
                        map((product) => {
                            if (product.curriculumType === 1) {
                                product.group = '주간 집중관리';
                            } else if (product.curriculumType === 2) {
                                product.group = '심야 집중관리';
                            }
                            if (Number(paymentType === 'extensionRecurring') === 1 && Number(product.duration) === 365) {
                                product.isRecurring = 0;
                            }
                            return product;
                        }),
                        filter((product) => product.duration < 180),
                    );
                    setProducts(products);
                },
                contentType: 'application/json',
                dataType: 'json',
            });
        };
        getProducts();
    }, [paymentType, recurring]);

    const productCard = (product) => {
        let cardWidth;
        if (isMobile) {
            cardWidth = contentWidth * 3.1;
        } else {
            cardWidth = contentWidth;
        }

        return (
            <div onClick={openDialog.bind(null, product)}>
                <img style={{ width: '100%' }} alt={'product_card'} src={'./images/payment/product_card.jpg'} />
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        width: '100%',
                        padding: '5% 10%',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: cardWidth * 10.5,
                        }}
                    >
                        <div>
                            <span
                                style={{
                                    fontSize: cardWidth * 1.5,
                                    fontWeight: 'bold',
                                }}
                            >
                                {product.name}
                            </span>
                            <br />
                            {Number(extension) !== 1 && (
                                <span
                                    style={{
                                        fontSize: cardWidth * 1,
                                        color: 'rgb(18,130,254)',
                                    }}
                                >
                                    (100%환불보장 {product.refundDate ? product.refundDate : 7}일)
                                </span>
                            )}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                marginTop: 'auto',
                                justifyContent: 'space-between',
                                alignItems: 'baseline',
                            }}
                        >
                            <div
                                style={{
                                    fontSize: cardWidth * 1.3,
                                    flexGrow: 1,
                                    color: '#aaa',
                                    position: 'relative',
                                }}
                            >
                                <img
                                    alt={'arrow'}
                                    style={{
                                        position: 'absolute',
                                        bottom: cardWidth * 0.7,
                                        width: '100%',
                                    }}
                                    src={'./images/payment/arrow.png'}
                                />
                                {changeCurrencyUnit(product.originalPrice)}원
                            </div>
                            <div
                                style={{
                                    fontSize: cardWidth * 1.8,
                                    fontWeight: 'bold',
                                    color: 'hotpink',
                                }}
                            >
                                {changeCurrencyUnit(product.price)}원
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const dialog = (
        <Dialog onKeyDown={onKeyDown} open={isDialogOpen} onBackdropClick={closeDialog}>
            <div style={{ padding: 24 }}>
                <div
                    style={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        marginBottom: '10px',
                    }}
                >
                    {product && product.group + ' ' + product.name}
                </div>
                <div
                    style={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        marginBottom: '10px',
                    }}
                >
                    {product && changeCurrencyUnit(product.price)}원
                </div>
                <div style={{ margin: '24px 0px' }}>
                    <TextField
                        placeholder="이름"
                        name="name"
                        fullWidth={true}
                        style={{ marginBottom: 16 }}
                        onChange={onChange.bind(null, 'name')}
                        error={!/^[ㄱ-ㅎㅏ-ㅣ가-힣a-z0-9_-].{1,10}$/.test(name)}
                        value={name}
                    />
                    <TextField
                        placeholder="연락처"
                        name="phoneNumber"
                        type="number"
                        fullWidth={true}
                        onChange={onChange.bind(null, 'phoneNumber')}
                        error={!/^(?=.*[0-9]).{10,11}$/.test(phoneNumber)}
                        value={phoneNumber}
                    />
                </div>
                <div>
                    {product && product.isRecurring !== 1 && (
                        <RadioGroup onChange={onChangePayMethod} defaultValue={'card'}>
                            <FormControlLabel value="card" control={<Radio />} label="신용카드" />
                            <FormControlLabel value="vbank" control={<Radio />} label="가상계좌" />
                        </RadioGroup>
                    )}
                </div>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <div style={{ fontSize: '14px' }}></div>
                </div>
                <Button
                    color={'secondary'}
                    variant={'contained'}
                    style={{
                        width: '100%',
                        position: 'relative',
                    }}
                    onClick={purchase}
                >
                    결제
                </Button>
            </div>
        </Dialog>
    );

    if (isMobile) {
        return (
            <div style={{ display: 'flex' }}>
                <div style={{ flex: `${contentMaxWidth}px 0 1`, margin: '0 auto' }}>
                    <div>
                        <img style={{ display: 'block', width: '100%' }} alt="background_1.jpg" src="./images/payment/m_background_1.jpg" />
                    </div>
                    <div>
                        <img style={{ display: 'block', width: '100%' }} alt="background_2.jpg" src="./images/payment/m_background_2.jpg" />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative',
                            width: '100%',
                            top: 0,
                            backgroundColor: 'rgb(240, 240, 240)',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {productsType1.length > 0 && (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <img style={{ display: 'block', width: '100%', marginTop: '10%' }} alt="item_1.png" src="./images/payment/item_1.png" />
                                <div
                                    style={{
                                        display: 'inline-flex',
                                        width: '100%',
                                        alignItems: 'center',
                                        flexFlow: 'wrap',
                                        marginTop: '6%',
                                    }}
                                >
                                    {productsType1.map((product, key) => {
                                        return (
                                            <div key={key} style={styles.mobileButton}>
                                                {productCard(product)}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                        {productsType2.length > 0 && (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <img style={{ display: 'block', width: '100%', marginTop: '10%' }} alt="item_2.png" src="./images/payment/item_2.png" />
                                <div
                                    style={{
                                        display: 'inline-flex',
                                        width: '100%',
                                        alignItems: 'center',
                                        flexFlow: 'wrap',
                                        marginTop: '6%',
                                    }}
                                >
                                    {productsType2.map((product, key) => {
                                        return (
                                            <div key={key} style={styles.mobileButton}>
                                                {productCard(product)}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                        <div
                            style={{
                                textAlign: 'center',
                                fontSize: contentWidth * 0.02,
                                color: '#aaa',
                                padding: '10% 0% 6% 0%',
                            }}
                        >
                            카카오 & 밀당영어의 모든 프로그램은 정해진 환불기간 안에
                            <br />
                            복잡한 절차 없이 환불받으실 수 있습니다.
                        </div>
                    </div>
                    <div>
                        <img style={{ display: 'block', width: '100%' }} alt="background_4.jpg" src="./images/payment/m_background_4.jpg" />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            padding: 32,
                            fontSize: 12,
                            backgroundColor: '#F2F2F2',
                            justifyContent: 'center',
                        }}
                    >
                        <ProductRules products={products} style={{ display: 'inline-block', width: '60%' }} />
                    </div>
                    <Footer isMobile={isMobile} />
                    {dialog}
                </div>
            </div>
        );
    } else {
        return (
            <div style={{ display: 'flex' }}>
                <div style={{ flex: `${contentMaxWidth}px 0 1`, margin: '0 auto' }}>
                    <img style={{ display: 'block', width: '100%' }} alt="background_1.jpg" src="./images/payment/background_1.jpg" />
                    <img style={{ display: 'block', width: '100%' }} alt="background_2.jpg" src="./images/payment/background_2.jpg" />
                    <img style={{ display: 'block', width: '100%' }} alt="background_3.jpg" src="./images/payment/background_3.jpg" />
                    <div style={{ backgroundColor: 'rgb(240, 240, 240)' }}>
                        {productsType1.length > 0 && (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingTop: '5%' }}>
                                <img style={{ display: 'block', width: '40%' }} alt="item_1.png" src="./images/payment/item_1.png" />
                                <div
                                    style={{
                                        display: 'inline-flex',
                                        width: '60%',
                                        alignItems: 'center',
                                        flexFlow: 'wrap',
                                        marginTop: '3%',
                                    }}
                                >
                                    {productsType1.map((product, key) => {
                                        return (
                                            <div key={key} style={styles.button}>
                                                {productCard(product)}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                        {productsType2.length > 0 && (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingTop: '5%' }}>
                                <img style={{ display: 'block', width: '40%' }} alt="item_2.png" src="./images/payment/item_2.png" />
                                <div
                                    style={{
                                        display: 'inline-flex',
                                        width: '60%',
                                        alignItems: 'center',
                                        flexFlow: 'wrap',
                                        marginTop: '3%',
                                    }}
                                >
                                    {productsType2.map((product, key) => {
                                        return (
                                            <div key={key} style={styles.button}>
                                                {productCard(product)}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                        <div
                            style={{
                                textAlign: 'center',
                                fontSize: contentWidth * 1,
                                color: '#aaa',
                                padding: '5% 0% 3% 0%',
                            }}
                        >
                            카카오 & 밀당영어의 모든 프로그램은 정해진 환불기간 안에
                            <br />
                            복잡한 절차 없이 환불받으실 수 있습니다.
                        </div>
                    </div>
                    <img style={{ display: 'block', width: '100%' }} alt="background_4.jpg" src="./images/payment/background_4.jpg" />
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            padding: 32,
                            backgroundColor: '#F2F2F2',
                            justifyContent: 'center',
                        }}
                    >
                        <ProductRules products={products} style={{ display: 'inline-block', width: '60%' }} />
                    </div>
                    <Footer isMobile={isMobile} />
                    {dialog}
                </div>
            </div>
        );
    }
}

export default Payment;
