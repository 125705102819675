import React, { useState, useRef } from "react";
import queryString from "query-string";
import endOfDay from "date-fns/endOfDay";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "./Manager.module.css";
import { generateEvent } from "../utils/event";

async function postData(url = "", data = {}) {
  const response = await fetch(url, {
    method: "POST",
    // mode: "no-cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}
function getEventTypeQueryString(eventType) {
  switch (eventType) {
    case "1":
      return { normal: 1 };
    case "2":
      return {};
    case "3":
      return { extension: 1 };
    case "4":
      return { extensionRecurring: 1 };
    case "5":
      return { grade: 3 };
    case "6":
      return { grade: 3, recurring: 1 };
    default:
      break;
  }
}

function Manager(props) {
  const [isLogined, setIsLogined] = useState(false);
  const [date, setDate] = useState("");
  const [eventType, setEventType] = useState("2");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const elementRef = useRef();

  function handleSubmit(e) {
    e.preventDefault();
    postData("https://english.management/dev/users/me/token", {
      email,
      password,
      type: "admin",
    }).then((data) => {
      if (data.data.token) {
        setIsLogined(true);
      }
    });
  }
  function handleEventTypeChange(e) {
    setEventType(e.target.value);
  }

  const endOfEventDay = date ? endOfDay(new Date(date)) : null;

  function copy(e) {
    if (elementRef.current) {
      const range = document.createRange();
      range.selectNode(elementRef.current); //changed here
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
    }
  }
  const queryStringObj = {
    ...(endOfEventDay ? { event: generateEvent(endOfEventDay) } : {}),
    ...(eventType ? getEventTypeQueryString(eventType) : {}),
  };

  if (isLogined) {
    return (
      <div className={styles.root}>
        <Container maxWidth='sm'>
          <Paper className={styles.eventGen}>
            <Typography variant='h6'>주소 생성기</Typography>
            <TextField
              id='date'
              label='이벤트 종료일'
              type='date'
              color='secondary'
              onChange={(e) => {
                setDate(e.target.value);
              }}
              value={date}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              id='eventType'
              name='eventType'
              label='이벤트 타입'
              select
              value={eventType}
              onChange={handleEventTypeChange}
            >
              <MenuItem value='6'>고3 연장 결제</MenuItem>
              <MenuItem value='5'>고3 결제</MenuItem>
              <MenuItem value='4'>연장 정기 결제</MenuItem>
              <MenuItem value='3'>정기 결제</MenuItem>
              <MenuItem value='1'>일반 결제</MenuItem>
              <MenuItem value='2'>기본 페이지</MenuItem>
            </TextField>

            {/* date */}
            {(endOfEventDay || eventType) && (
              <>
                <div className={styles.address}>
                  <Typography ref={elementRef}>
                    {window.location.origin}
                    {Object.keys(queryStringObj).length > 0
                      ? `/?${queryString.stringify(queryStringObj)}`
                      : ""}
                  </Typography>
                  <Button variant='contained' color='secondary' onClick={copy}>
                    복사하기
                  </Button>
                </div>
                {endOfEventDay && (
                  <Typography>
                    <b>
                      {endOfEventDay.toLocaleDateString("ko-KR")}{" "}
                      {endOfEventDay.toLocaleTimeString("ko-KR")}
                    </b>{" "}
                    이후에 만료 됩니다.
                  </Typography>
                )}
              </>
            )}
          </Paper>
        </Container>
      </div>
    );
  }

  return (
    <div>
      <Container maxWidth='sm'>
        <Paper className={styles.root}>
          <form onSubmit={handleSubmit} className={styles.loginForm}>
            <TextField
              type='email'
              id='email'
              label='email'
              color='secondary'
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              variant='outlined'
              autoComplete='email'
            />
            <TextField
              autoComplete='current-password'
              type='password'
              id='password'
              label='password'
              color='secondary'
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              variant='outlined'
            />
            <Button variant='contained' type='submit' color='secondary'>
              로그인
            </Button>
          </form>
        </Paper>
      </Container>
    </div>
  );
}

export default Manager;
