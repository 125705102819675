import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Introduction from '../pages/Introduction';
import Payment from '../pages/Payment';
import PaymentResult from '../pages/PaymentResult';
import Manager from '../pages/Manager';
// import { isEventEnded } from '../utils/event';

const paymentTypeMap = new Map([
    ['normal', 1],
    ['recurring', 2],
    ['extension', 3],
    ['extensionRecurring', 4],
    ['grade3', 5],
    ['grade3Recurring', 6],
    ['renewal', 7],
    ['renewalRecurring', 8],
    ['math', 9],
]);

function App() {
    const search = new URLSearchParams(window.location.search);
    // 변수로 쓰임
    const token = decodeURIComponent(search.get('token'));
    // const event = decodeURIComponent(search.get('event'));
    const direct = decodeURIComponent(search.get('direct'));
    const impUid = decodeURIComponent(search.get('imp_uid'));
    const merchantUid = decodeURIComponent(search.get('merchant_uid'));
    const body = decodeURIComponent(search.get('body'));
    const trans = decodeURIComponent(search.get('trans'));
    // 페이지를 나누는데 쓰임
    const introduction = decodeURIComponent(search.get('introduction'));
    const result = decodeURIComponent(search.get('result'));
    // 결제 페이지 타입
    const normal = Number(decodeURIComponent(search.get('normal'))) || 0;
    const recurring = Number(decodeURIComponent(search.get('recurring'))) || 0;
    const extension = Number(decodeURIComponent(search.get('extension'))) || 0;
    const extensionRecurring = Number(decodeURIComponent(search.get('extensionRecurring'))) || 0;
    const grade = Number(decodeURIComponent(search.get('grade'))) || 0;
    const renewal = Number(decodeURIComponent(search.get('renewal'))) || 0;
    const renewalRecurring = Number(decodeURIComponent(search.get('renewalRecurring'))) || 0;

    let pageType = 'normal';

    if (recurring === 1) {
        if (grade === 3) {
            pageType = 'grade3Recurring';
        } else if (extension === 1) {
            pageType = 'extensionRecurring';
        } else if (renewal === 1) {
            pageType = 'renewalRecurring';
        } else {
            pageType = 'recurring';
        }
    } else {
        if (grade === 3) {
            pageType = 'grade3';
        } else if (extension === 1) {
            pageType = 'extension';
        } else if (renewal === 1) {
            pageType = 'renewal';
        } else if (normal === 1) {
            pageType = 'normal';
        }
    }

    if (extensionRecurring === 1) {
        pageType = 'extensionRecurring';
    } else if (renewalRecurring === 1) {
        pageType = 'renewalRecurring';
    }

    const paymentType = paymentTypeMap.get(pageType);
    let paymentRecurring = recurring;
    if (pageType === 'recurring' || pageType === 'extensionRecurring' || pageType === 'renewalRecurring') {
        paymentRecurring = 1;
    }

    const [width, setWidth] = useState(window.innerWidth);
    const isMobile = width <= 734;

    // const isEvent = !isEventEnded(event);

    useEffect(() => {
        window.addEventListener('resize', () => setWidth(window.innerWidth));
    }, []);

    let page;

    if (introduction !== 'null') {
        typeof window.gtag === 'function' &&
            window.gtag('config', 'UA-100968789-9', {
                page_title: '안내 페이지',
                page_path: '/introduction',
            });
        page = <Introduction isMobile={isMobile} />;
    } else if (result !== 'null') {
        page = <PaymentResult isMobile={isMobile} impUid={impUid} merchantUid={merchantUid} recurring={recurring} body={body} trans={trans} token={token} />;
    } else {
        typeof window.gtag === 'function' &&
            window.gtag('config', 'UA-100968789-9', {
                page_title: '결제 페이지',
                page_path: '/payment',
            });
        page = <Payment paymentType={paymentType} token={token} direct={direct} extension={extension} recurring={paymentRecurring} isMobile={isMobile} />;
    }

    return (
        <Router>
            <Switch>
                <Route path="/manager">
                    <Manager />
                </Route>
                <Route path="/introduction">
                    <Introduction isMobile={isMobile} />
                </Route>
                <Route path="/result">
                    <PaymentResult
                        isMobile={isMobile}
                        impUid={impUid}
                        merchantUid={merchantUid}
                        recurring={recurring}
                        body={body}
                        trans={trans}
                        token={token}
                    />
                </Route>
                <Route path="/index">
                    <Payment paymentType={recurring ? 2 : 1} token={token} recurring={recurring} direct={direct} isMobile={isMobile} />
                </Route>
                <Route path="/extension">
                    <Payment paymentType={recurring ? 4 : 3} token={token} recurring={recurring} direct={direct} extension={1} isMobile={isMobile} />
                </Route>
                <Route path="/grade3">
                    <Payment paymentType={recurring ? 6 : 5} token={token} recurring={recurring} direct={direct} isMobile={isMobile} />
                </Route>
                <Route path="/renewal">
                    <Payment paymentType={recurring ? 8 : 7} token={token} recurring={recurring} direct={direct} isMobile={isMobile} />
                </Route>
                <Route path="/math">
                    <Payment paymentType={9} token={token} recurring={recurring} direct={direct} isMobile={isMobile} />
                </Route>
                <Route>
                    <div>{page}</div>
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
