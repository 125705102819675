import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import dateformat from 'dateformat';
import Footer from '../components/Footer';

function PaymentResult(props) {
  document.body.style.backgroundColor = '#f2f2f2';

  useEffect(() => {
    //무통장입금
    if (Number(props.trans) === 1) {
      let body = JSON.parse(props.body);
      window.$.ajax({
        type: 'GET',
        url: `https://english.management/dev/payments/${body.insertId}`,
        success: function (result) {
          result.data.payments.status = 'ready';
          setPayments(result.data.payments);

          typeof window.gtag === 'function' &&
            window.gtag('config', 'UA-100968789-9', {
              page_title: '결제 결과 페이지',
              page_path: '/payment_result',
            });
        },
        contentType: 'application/json',
        dataType: 'json',
      });
      //정기결제
    } else if (Number(props.recurring) === 1) {
      window.$.ajax({
        type: 'POST',
        url: `https://english.management/dev/payments/billings`,
        contentType: 'application/json',
        dataType: 'json',
        data: props.body,
        success: function (result) {
          window.location.href =
            window.origin +
            `/?result=1&imp_uid=${result.data.impUid}&merchant_uid=${result.data.merchantUid}`;
        },
      });
    } else {
      window.$.ajax({
        type: 'GET',
        url: `https://english.management/dev/payments?imp_uid=${props.impUid}&merchant_uid=${props.merchantUid}`,
        success: function (result) {
          setPayments(result.data.payments);
          if (result.data.payments && result.data.payments.status === null) {
            window.$.ajax({
              type: 'PUT',
              url: `https://english.management/dev/payments?imp_uid=${props.impUid}&merchant_uid=${props.merchantUid}`,
              success: function () {
                setTimeout(() => window.location.reload(), 1000);
              },
              contentType: 'application/json',
              dataType: 'json',
            });
          } else {
            typeof window.gtag === 'function' &&
              window.gtag('config', 'UA-100968789-9', {
                page_title: '결제 결과 페이지',
                page_path: '/payment_result',
              });
          }
        },
        contentType: 'application/json',
        dataType: 'json',
      });
    }
  }, [props.impUid, props.merchantUid, props.trans, props.recurring, props.body]);

  const [payments, setPayments] = useState({});

  const clickTalk = () => {
    window.location.href = 'https://talk.naver.com/ct/wc6lhs';
  };

  let headerTitle;
  let headerDetail;
  let table;
  if (payments) {
    if (payments.status === 'ready' || payments.status === 'paid') {
      headerTitle = '주문 성공';
    } else if (payments.status === 'failed') {
      headerTitle = '결제 실패';
      headerDetail = payments.failReason;
    } else if (payments.status === 'cancelled') {
      headerTitle = '결제 취소';
      headerDetail = payments.cancelReason;
    } else if (payments.status === undefined || payments.status === null) {
      headerTitle = '잠시만 기다려주세요';
      headerDetail = (
        <span>
          최대 10초까지 시간이 걸릴 수 있습니다.
          <br />
          결제정보가 확인되지 않는다면{' '}
          <span
            style={{
              color: 'rgb(76, 175, 80)',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
            onClick={clickTalk}
          >
            네이버 톡톡
          </span>
          으로 문의주세요
        </span>
      );
    }
  } else {
    headerTitle = '잘못된 페이지입니다';
  }

  if (payments && (payments.status === 'ready' || payments.status === 'paid')) {
    table = (
      <div
        style={{
          margin: '50px auto',
          maxWidth: window.innerWidth >= 1069 ? 800 : '95%',
        }}
      >
        <h4>주문정보</h4>
        <Table>
          <TableBody displayRowCheckbox={false}>
            <TableRow selectable={false}>
              <TableCell style={{ width: '90px' }}>주문번호</TableCell>
              <TableCell>{payments.impUid}</TableCell>
            </TableRow>
            <TableRow selectable={false}>
              <TableCell>상품명</TableCell>
              <TableCell>{payments.name}</TableCell>
            </TableRow>
            <TableRow selectable={false}>
              <TableCell>주문금액</TableCell>
              <TableCell>{payments.amount}원</TableCell>
            </TableRow>
            <TableRow selectable={false}>
              <TableCell style={{ width: '70px' }}>주문자명</TableCell>
              <TableCell>{payments.buyerName}</TableCell>
            </TableRow>
            <TableRow selectable={false}>
              <TableCell>연락처</TableCell>
              <TableCell>{payments.buyerTel}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div style={{ fontSize: 14, textAlign: 'justify', marginTop: '30px' }}>
          추가적으로 궁금한 내용 있으시면 1899-0508 또는{' '}
          <span
            style={{
              color: 'rgb(76, 175, 80)',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
            onClick={clickTalk}
          >
            네이버 톡톡
          </span>
          으로 문의주세요!
        </div>
        {payments.status === 'ready' && (
          <div>
            {payments.payMethod === 'trans' ? (
              <div>
                <h4>입금정보</h4>
                {
                  <Table>
                    <TableBody displayRowCheckbox={false}>
                      <TableRow selectable={false}>
                        <TableCell style={{ width: '90px' }}>예금주</TableCell>
                        <TableCell>아이헤이트플라잉버그스</TableCell>
                      </TableRow>
                      <TableRow selectable={false}>
                        <TableCell>은행명</TableCell>
                        <TableCell>우리은행</TableCell>
                      </TableRow>
                      <TableRow selectable={false}>
                        <TableCell>계좌번호</TableCell>
                        <TableCell>1005-102-320981</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                }
              </div>
            ) : (
              <div>
                <h4>가상계좌 입금정보</h4>
                <Table>
                  <TableBody displayRowCheckbox={false}>
                    <TableRow selectable={false}>
                      <TableCell style={{ width: '90px' }}>예금주</TableCell>
                      <TableCell>{payments.vbankHolder}</TableCell>
                    </TableRow>
                    <TableRow selectable={false}>
                      <TableCell>입금기한</TableCell>
                      <TableCell>{dateformat(payments.vbankDate, 'yyyy-mm-dd HH:MM:ss')}</TableCell>
                    </TableRow>
                    <TableRow selectable={false}>
                      <TableCell>은행명</TableCell>
                      <TableCell>{payments.vbankName}</TableCell>
                    </TableRow>
                    <TableRow selectable={false}>
                      <TableCell>계좌번호</TableCell>
                      <TableCell>{payments.vbankNum}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <div
        style={{
          flex: 1,
          margin: '50px auto',
          maxWidth: window.innerWidth >= 1069 ? 800 : '95%',
        }}
      >
        <div
          style={{
            fontSize: '24px',
            textAlign: 'center',
            fontWeight: 'bold',
            marginBottom: '30px',
          }}
        >
          {headerTitle}
        </div>
        <div
          style={{
            fontSize: '16px',
            textAlign: 'center',
            marginBottom: '30px',
          }}
        >
          {headerDetail}
          {table}
        </div>
      </div>
      <Footer isMobile={props.isMobile} />
    </div>
  );
}

export default PaymentResult;
