import isBefore from "date-fns/isBefore";

const RADIX = 36;

function reverseString(str) {
  let newString = "";
  for (let i = str.length - 1; i >= 0; i--) {
    newString += str[i];
  }
  return newString;
}

export const generateEvent = (date) => {
  const dateTime = +date;
  return reverseString(dateTime.toString(RADIX));
};

const isNumber = function isNumber(value) {
  return typeof value === "number" && isFinite(value);
};

export const parseEventString = (eventSearchString) => {
  const eventStr = reverseString(eventSearchString);
  let dateTime = parseInt(eventStr, RADIX);
  if (!isNumber(dateTime)) {
    dateTime = 0;
  }
  return new Date(dateTime);
};

export const isEventEnded = (eventSearchString) => {
  if (!eventSearchString) {
    return true;
  }
  const eventDate = parseEventString(eventSearchString);

  return isBefore(eventDate, new Date());
};
